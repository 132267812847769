<template>
  <CCard>
    <CCardHeader class="text-center bg-dark text-white">
      <b>{{$t('label.IncidentList')}}  </b>
    </CCardHeader>
    <CCardBody>
        <CustomTabs :active-tab="Tab" @update:activeTab="handleTab">
            <CustomTab :title="$t('label.IncidenceGroup')">
              <group-incidence-index :Tab="Tab"/>   
            </CustomTab>
            <CustomTab :title="$t('label.incidence')">
              <incidence-index :Tab="Tab"/>
            </CustomTab>
        </CustomTabs>
    </CCardBody>
  </CCard>
</template>
<script>
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import IncidenceIndex from './incidence/incidence-index';
import GroupIncidenceIndex from './group-incidence/group-incidence-index';

function handleTab(tab) {
  this.Tab = tab;
}
function data() {
  return {
    Tab: 0,
  };
}
export default {
  name: 'index',
  data,
  methods:{
    handleTab,
  },
  components: {
    CustomTabs,
    CustomTab,
    IncidenceIndex,
    GroupIncidenceIndex,
  },
};
</script>